<template>
    <div id="kof-tools">
        <div class="vx-row">
            <!-- OVERLAY CARD -->
            <div v-if="!isSupplier" class="vx-col w-full mb-6">
                <vx-card class="big-title-card-bg-black h-auto">
                    <h1 class="rg-big-title extrabold rg-title">
                    Información de <span>apoyo</span>.
                    </h1>
                </vx-card>
            </div>

            <!-- OVERLAY CARD -->
            <!-- <div class="vx-col w-full mb-6">
                <vx-card title="Descarga lo que necesites.">
                   <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris elementum odio eget nunc hendrerit, sed volutpat ipsum euismod. Pellentesque interdum lacus turpis. Vivamus vel nisi id risus dignissim auctor.</p>
                </vx-card>
            </div> -->
            <vs-divider v-if="isSupplier" class="mb-10 bold">INFORMACIÓN DE PROGRAMA</vs-divider>
            <!-- OVERLAY CARD -->
            <div class="vx-col w-full mb-6">
                <vx-card class="mb-base">
                    <div class="vx-row">
                        <!-- FORMATOS Y PROMOCIONALES -->
                        <div class="vx-col w-full">
                            <h3 class="mt-3 mb-5">Formatos y promocionales.</h3>

                            <div class="vx-row">
                                <div  v-if="!isSupplier" class="vx-col lg:w-1/2 md:w-3/4 w-full">
                                    <!-- LAYOUT -->
                                    <action-button
                                    id="flyer-venta"
                                    title="Layout para carga de prospectos."
                                    actionLink="/programs/kof/PROSPECTOS_LAYOUT_RG.csv"
                                    fileName="PROSPECTOS_LAYOUT_RG.csv"
                                    :download="true"
                                    :remoteFile="true"
                                    icon="DownloadIcon"
                                    color="rgred" />
                                </div>

                                <div class="vx-col lg:w-1/2 md:w-3/4 w-full">
                                    <!-- VENTA -->
                                    <action-button
                                    id="folleto-venta"
                                    title="Folleto de venta"
                                    actionLink="/programs/kof/RG_KOF_FOLLETO.pdf"
                                    fileName="RG_KOF_FOLLETO.pdf"
                                    :download="true"
                                    :remoteFile="true"
                                    icon="DownloadIcon"
                                    color="rgred"
                                    />
                                </div>
                            
                                <div class="vx-col lg:w-1/2 md:w-3/4 w-full">
                                    <!-- VENTA -->
                                    <action-button
                                    id="flyer-venta"
                                    title="Flyer de venta"
                                    actionLink="/programs/kof/RG_KOF_FLYER.pdf"
                                    fileName="RG_KOF_FLYER.pdf"
                                    :download="true"
                                    :remoteFile="true"
                                    icon="DownloadIcon"
                                    color="rgred" />
                                </div>

                                <div class="vx-col lg:w-1/2 md:w-3/4 w-full">
                                    <!-- VIDEOS PROMOCIONALES -->
                                    <action-button
                                    id="promo-instalador"
                                    title="Video promocional para instaladores"
                                    actionLink="/programs/kof/RG_KOF_PROMOCIONAL_INSTALADORES.mp4"
                                    fileName="RG_KOF_PROMOCIONAL_INSTALADORES.mp4"
                                    :download="true"
                                    :remoteFile="true"
                                    icon="PlayIcon"
                                    color="rgred" />
                                </div>

                                <div class="vx-col lg:w-1/2 md:w-3/4 w-full">
                                    <!-- VIDEOS PROMOCIONALES -->
                                    <action-button
                                    id="promo-prevendedores"
                                    title="Video promocional para prevendedores"
                                    actionLink="/programs/kof/RG_KOF_PROMOCIONAL_VENDEDORES.mp4"
                                    fileName="RG_KOF_PROMOCIONAL_VENDEDORES.mp4"
                                    :download="true"
                                    :remoteFile="true"
                                    icon="PlayIcon"
                                    color="rgred" />
                                </div>

                            </div>
                        </div>

                        <!-- TUTORIALES E INSTRUCCIONES -->
                        <div class="vx-col w-full">
                            <h3 class="mt-3 mb-5">Tutoriales e instrucciones.</h3>

                            <div class="vx-row">
                                <div class="vx-col lg:w-1/2 md:w-3/4 w-full">
                                    <!-- VENTA -->
                                    <action-button
                                    id="kof-manual"
                                    title="¿Cómo funciona el programa?"
                                    actionLink="/programs/kof/RG_KOF_MANUAL.pdf"
                                    fileName="RG_KOF_MANUAL.pdf"
                                    :download="true"
                                    :remoteFile="true"
                                    icon="DownloadIcon"
                                    color="rgred"
                                    />
                                </div>
                            
                                <!-- <div class="vx-col lg:w-1/2 md:w-3/4 w-full">
                                    <action-button
                                    id="PMCC"
                                    title="¿Cómo actualizar el estado de las visitas?"
                                    actionLink="/formatos/Formato-Consulta-Informacion-Crediticia-CC-Persona-Moral.pdf"
                                    fileName="Formato-Consulta-Informacion-Crediticia-CC-Persona-Moral.pdf"
                                    :download="true"
                                    :remoteFile="true"
                                    icon="PlayIcon"
                                    color="rgred" />
                                </div> -->

                                <!-- <div class="vx-col lg:w-1/2 md:w-3/4 w-full">
                                    <action-button
                                    id="SCAPFAE"
                                    title="¿Cómo funciona RedGirasol?"
                                    actionLink="/guias/Guía-Solicitud-PF-PFAE.pdf"
                                    fileName="Guía-Solicitud-PF-PFAE.pdf"
                                    :download="true"
                                    :remoteFile="true"
                                    icon="PlayIcon"
                                    color="rgred" />
                                </div> -->

                            </div>
                        </div>
                    </div>

                </vx-card>
            </div>
        </div>
    </div>
</template>

<script>
import ActionButton from '@components/supplier/ActionButton.vue'
export default {
    name:"programTools",
    props: ["isSupplier"],
    components: {
        ActionButton
    }

}
</script>

<style>
    .rg-red {
        color: #F04A4A !important;
    }
</style>