<template>
  <vx-card class="overflow-hidden download-action mb-5">
    <div slot="no-body">
      <div class="flex justify-between flex-row-reverse items-center py-2 px-5" v-on:click="click">
        <feather-icon  :icon="icon" class="p-2 icon-action inline-flex rounded-full" :class="[`text-${color}`]" :style="{background: `rgba(var(--vs-${color}),.15)`, border: `2px solid`}" style="width: 2.5rem;height: 2.5rem;"></feather-icon>
        <div class="truncate">
          <h6 class="mb-1 font-bold">{{ title }}</h6>
        </div>
      </div>
    </div>
  </vx-card>
  
</template>

<script>
export default {
  name: "action-button",
  props: {
    id: String,
    title: String,
    actionLink: String,
    remoteFile: Boolean,
    download: Boolean,
    icon: String,
    color: String,
    fileName: String,
    isVideo: Boolean
  },
  computed: {
    actionUrl: function() {
      if (this.download == true) {
        if(this.remoteFile == true){
          return this.StorageURL + this.actionLink;
        }
        else {
          return this.ApiDomain + this.actionLink;
        }
      }
      else return this.actionLink;
    },
  },
  methods: {
    click: function() {
      if(this.download){
        window.open(this.actionUrl, "_blank");
      }
      else if(this.isVideo){
        window.open(this.actionUrl, "_blank");
      } else {
        this.$router.push(this.actionUrl);
      }
    }
  }
};
</script>
